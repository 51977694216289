import { __extends } from 'tslib';

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview Firebase constants. Some of these (@defines) can be overridden at compile-time.
 */
var CONSTANTS = {
 /**
 * @define {boolean} Whether this is the client Node.js SDK.
 */
 NODE_CLIENT: false,
 /**
 * @define {boolean} Whether this is the Admin Node.js SDK.
 */
 NODE_ADMIN: false,
 /**
 * Firebase SDK Version
 */
 SDK_VERSION: '${JSCORE_VERSION}'
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Throws an error if the provided assertion is falsy
 * @param {*} assertion The assertion to be tested for falsiness
 * @param {!string} message The message to display if the check fails
 */
var assert = function (assertion, message) {
 if (!assertion) {
 throw assertionError(message);
 }
};
/**
 * Returns an Error object suitable for throwing.
 * @param {string} message
 * @return {!Error}
 */
var assertionError = function (message) {
 return new Error('Firebase Database (' +
 CONSTANTS.SDK_VERSION +
 ') INTERNAL ASSERT FAILED: ' +
 message);
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var stringToByteArray = function (str) {
 // TODO(user): Use native implementations if/when available
 var out = [], p = 0;
 for (var i = 0; i < str.length; i++) {
 var c = str.charCodeAt(i);
 if (c < 128) {
 out[p++] = c;
 }
 else if (c < 2048) {
 out[p++] = (c >> 6) | 192;
 out[p++] = (c & 63) | 128;
 }
 else if ((c & 0xfc00) == 0xd800 &&
 i + 1 < str.length &&
 (str.charCodeAt(i + 1) & 0xfc00) == 0xdc00) {
 // Surrogate Pair
 c = 0x10000 + ((c & 0x03ff) << 10) + (str.charCodeAt(++i) & 0x03ff);
 out[p++] = (c >> 18) | 240;
 out[p++] = ((c >> 12) & 63) | 128;
 out[p++] = ((c >> 6) & 63) | 128;
 out[p++] = (c & 63) | 128;
 }
 else {
 out[p++] = (c >> 12) | 224;
 out[p++] = ((c >> 6) & 63) | 128;
 out[p++] = (c & 63) | 128;
 }
 }
 return out;
};
/**
 * Turns an array of numbers into the string given by the concatenation of the
 * characters to which the numbers correspond.
 * @param {Array} bytes Array of numbers representing characters.
 * @return {string} Stringification of the array.
 */
var byteArrayToString = function (bytes) {
 // TODO(user): Use native implementations if/when available
 var out = [], pos = 0, c = 0;
 while (pos < bytes.length) {
 var c1 = bytes[pos++];
 if (c1 < 128) {
 out[c++] = String.fromCharCode(c1);
 }
 else if (c1 > 191 && c1 < 224) {
 var c2 = bytes[pos++];
 out[c++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
 }
 else if (c1 > 239 && c1 < 365) {
 // Surrogate Pair
 var c2 = bytes[pos++];
 var c3 = bytes[pos++];
 var c4 = bytes[pos++];
 var u = (((c1 & 7) << 18) | ((c2 & 63) << 12) | ((c3 & 63) << 6) | (c4 & 63)) -
 0x10000;
 out[c++] = String.fromCharCode(0xd800 + (u >> 10));
 out[c++] = String.fromCharCode(0xdc00 + (u & 1023));
 }
 else {
 var c2 = bytes[pos++];
 var c3 = bytes[pos++];
 out[c++] = String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
 }
 }
 return out.join('');
};
// Static lookup maps, lazily populated by init_()
var base64 = {
 /**
 * Maps bytes to characters.
 * @type {Object}
 * @private
 */
 byteToCharMap_: null,
 /**
 * Maps characters to bytes.
 * @type {Object}
 * @private
 */
 charToByteMap_: null,
 /**
 * Maps bytes to websafe characters.
 * @type {Object}
 * @private
 */
 byteToCharMapWebSafe_: null,
 /**
 * Maps websafe characters to bytes.
 * @type {Object}
 * @private
 */
 charToByteMapWebSafe_: null,
 /**
 * Our default alphabet, shared between
 * ENCODED_VALS and ENCODED_VALS_WEBSAFE
 * @type {string}
 */
 ENCODED_VALS_BASE: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz' + '0123456789',
 /**
 * Our default alphabet. Value 64 (=) is special; it means "nothing."
 * @type {string}
 */
 get ENCODED_VALS() {
 return this.ENCODED_VALS_BASE + '+/=';
 },
 /**
 * Our websafe alphabet.
 * @type {string}
 */
 get ENCODED_VALS_WEBSAFE() {
 return this.ENCODED_VALS_BASE + '-_.';
 },
 /**
 * Whether this browser supports the atob and btoa functions. This extension
 * started at Mozilla but is now implemented by many browsers. We use the
 * ASSUME_* variables to avoid pulling in the full useragent detection library
 * but still allowing the standard per-browser compilations.
 *
 * @type {boolean}
 */
 HAS_NATIVE_SUPPORT: typeof atob === 'function',
 /**
 * Base64-encode an array of bytes.
 *
 * @param {Array|Uint8Array} input An array of bytes (numbers with
 * value in [0, 255]) to encode.
 * @param {boolean=} opt_webSafe Boolean indicating we should use the
 * alternative alphabet.
 * @return {string} The base64 encoded string.
 */
 encodeByteArray: function (input, opt_webSafe) {
 if (!Array.isArray(input)) {
 throw Error('encodeByteArray takes an array as a parameter');
 }
 this.init_();
 var byteToCharMap = opt_webSafe
 ? this.byteToCharMapWebSafe_
 : this.byteToCharMap_;
 var output = [];
 for (var i = 0; i < input.length; i += 3) {
 var byte1 = input[i];
 var haveByte2 = i + 1 < input.length;
 var byte2 = haveByte2 ? input[i + 1] : 0;
 var haveByte3 = i + 2 < input.length;
 var byte3 = haveByte3 ? input[i + 2] : 0;
 var outByte1 = byte1 >> 2;
 var outByte2 = ((byte1 & 0x03) << 4) | (byte2 >> 4);
 var outByte3 = ((byte2 & 0x0f) << 2) | (byte3 >> 6);
 var outByte4 = byte3 & 0x3f;
 if (!haveByte3) {
 outByte4 = 64;
 if (!haveByte2) {
 outByte3 = 64;
 }
 }
 output.push(byteToCharMap[outByte1], byteToCharMap[outByte2], byteToCharMap[outByte3], byteToCharMap[outByte4]);
 }
 return output.join('');
 },
 /**
 * Base64-encode a string.
 *
 * @param {string} input A string to encode.
 * @param {boolean=} opt_webSafe If true, we should use the
 * alternative alphabet.
 * @return {string} The base64 encoded string.
 */
 encodeString: function (input, opt_webSafe) {
 // Shortcut for Mozilla browsers that implement
 // a native base64 encoder in the form of "btoa/atob"
 if (this.HAS_NATIVE_SUPPORT && !opt_webSafe) {
 return btoa(input);
 }
 return this.encodeByteArray(stringToByteArray(input), opt_webSafe);
 },
 /**
 * Base64-decode a string.
 *
 * @param {string} input to decode.
 * @param {boolean=} opt_webSafe True if we should use the
 * alternative alphabet.
 * @return {string} string representing the decoded value.
 */
 decodeString: function (input, opt_webSafe) {
 // Shortcut for Mozilla browsers that implement
 // a native base64 encoder in the form of "btoa/atob"
 if (this.HAS_NATIVE_SUPPORT && !opt_webSafe) {
 return atob(input);
 }
 return byteArrayToString(this.decodeStringToByteArray(input, opt_webSafe));
 },
 /**
 * Base64-decode a string.
 *
 * In base-64 decoding, groups of four characters are converted into three
 * bytes. If the encoder did not apply padding, the input length may not
 * be a multiple of 4.
 *
 * In this case, the last group will have fewer than 4 characters, and
 * padding will be inferred. If the group has one or two characters, it decodes
 * to one byte. If the group has three characters, it decodes to two bytes.
 *
 * @param {string} input Input to decode.
 * @param {boolean=} opt_webSafe True if we should use the web-safe alphabet.
 * @return {!Array} bytes representing the decoded value.
 */
 decodeStringToByteArray: function (input, opt_webSafe) {
 this.init_();
 var charToByteMap = opt_webSafe
 ? this.charToByteMapWebSafe_
 : this.charToByteMap_;
 var output = [];
 for (var i = 0; i < input.length;) {
 var byte1 = charToByteMap[input.charAt(i++)];
 var haveByte2 = i < input.length;
 var byte2 = haveByte2 ? charToByteMap[input.charAt(i)] : 0;
 ++i;
 var haveByte3 = i < input.length;
 var byte3 = haveByte3 ? charToByteMap[input.charAt(i)] : 64;
 ++i;
 var haveByte4 = i < input.length;
 var byte4 = haveByte4 ? charToByteMap[input.charAt(i)] : 64;
 ++i;
 if (byte1 == null || byte2 == null || byte3 == null || byte4 == null) {
 throw Error();
 }
 var outByte1 = (byte1 << 2) | (byte2 >> 4);
 output.push(outByte1);
 if (byte3 != 64) {
 var outByte2 = ((byte2 << 4) & 0xf0) | (byte3 >> 2);
 output.push(outByte2);
 if (byte4 != 64) {
 var outByte3 = ((byte3 << 6) & 0xc0) | byte4;
 output.push(outByte3);
 }
 }
 }
 return output;
 },
 /**
 * Lazy static initialization function. Called before
 * accessing any of the static map variables.
 * @private
 */
 init_: function () {
 if (!this.byteToCharMap_) {
 this.byteToCharMap_ = {};
 this.charToByteMap_ = {};
 this.byteToCharMapWebSafe_ = {};
 this.charToByteMapWebSafe_ = {};
 // We want quick mappings back and forth, so we precompute two maps.
 for (var i = 0; i < this.ENCODED_VALS.length; i++) {
 this.byteToCharMap_[i] = this.ENCODED_VALS.charAt(i);
 this.charToByteMap_[this.byteToCharMap_[i]] = i;
 this.byteToCharMapWebSafe_[i] = this.ENCODED_VALS_WEBSAFE.charAt(i);
 this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[i]] = i;
 // Be forgiving when decoding and correctly decode both encodings.
 if (i >= this.ENCODED_VALS_BASE.length) {
 this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(i)] = i;
 this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(i)] = i;
 }
 }
 }
 }
};
/**
 * URL-safe base64 encoding
 * @param {!string} str
 * @return {!string}
 */
var base64Encode = function (str) {
 var utf8Bytes = stringToByteArray(str);
 return base64.encodeByteArray(utf8Bytes, true);
};
/**
 * URL-safe base64 decoding
 *
 * NOTE: DO NOT use the global atob() function - it does NOT support the
 * base64Url variant encoding.
 *
 * @param {string} str To be decoded
 * @return {?string} Decoded result, if possible
 */
var base64Decode = function (str) {
 try {
 return base64.decodeString(str, true);
 }
 catch (e) {
 console.error('base64Decode failed: ', e);
 }
 return null;
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Do a deep-copy of basic JavaScript Objects or Arrays.
 */
function deepCopy(value) {
 return deepExtend(undefined, value);
}
/**
 * Copy properties from source to target (recursively allows extension
 * of Objects and Arrays). Scalar values in the target are over-written.
 * If target is undefined, an object of the appropriate type will be created
 * (and returned).
 *
 * We recursively copy all child properties of plain Objects in the source- so
 * that namespace- like dictionaries are merged.
 *
 * Note that the target can be a function, in which case the properties in
 * the source Object are copied onto it as static properties of the Function.
 */
function deepExtend(target, source) {
 if (!(source instanceof Object)) {
 return source;
 }
 switch (source.constructor) {
 case Date:
 // Treat Dates like scalars; if the target date object had any child
 // properties - they will be lost!
 var dateValue = source;
 return new Date(dateValue.getTime());
 case Object:
 if (target === undefined) {
 target = {};
 }
 break;
 case Array:
 // Always copy the array source and overwrite the target.
 target = [];
 break;
 default:
 // Not a plain Object - treat it as a scalar.
 return source;
 }
 for (var prop in source) {
 if (!source.hasOwnProperty(prop)) {
 continue;
 }
 target[prop] = deepExtend(target[prop], source[prop]);
 }
 return target;
}
// TODO: Really needed (for JSCompiler type checking)?
function patchProperty(obj, prop, value) {
 obj[prop] = value;
}

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var Deferred = /** @class */ (function () {
 function Deferred() {
 var _this = this;
 this.promise = new Promise(function (resolve, reject) {
 _this.resolve = resolve;
 _this.reject = reject;
 });
 }
 /**
 * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
 * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
 * and returns a node-style callback which will resolve or reject the Deferred's promise.
 * @param {((?function(?(Error)): (?|undefined))| (?function(?(Error),?=): (?|undefined)))=} callback
 * @return {!function(?(Error), ?=)}
 */
 Deferred.prototype.wrapCallback = function (callback) {
 var _this = this;
 return function (error, value) {
 if (error) {
 _this.reject(error);
 }
 else {
 _this.resolve(value);
 }
 if (typeof callback === 'function') {
 // Attaching noop handler just in case developer wasn't expecting
 // promises
 _this.promise.catch(function () { });
 // Some of our callbacks don't expect a value and our own tests
 // assert that the parameter length is 1
 if (callback.length === 1) {
 callback(error);
 }
 else {
 callback(error, value);
 }
 }
 };
 };
 return Deferred;
}());

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Returns navigator.userAgent string or '' if it's not defined.
 * @return {string} user agent string
 */
var getUA = function () {
 if (typeof navigator !== 'undefined' &&
 typeof navigator['userAgent'] === 'string') {
 return navigator['userAgent'];
 }
 else {
 return '';
 }
};
/**
 * Detect Cordova / PhoneGap / Ionic frameworks on a mobile device.
 *
 * Deliberately does not rely on checking `file://` URLs (as this fails PhoneGap in the Ripple emulator) nor
 * Cordova `onDeviceReady`, which would normally wait for a callback.
 *
 * @return {boolean} isMobileCordova
 */
var isMobileCordova = function () {
 return (typeof window !== 'undefined' &&
 !!(window['cordova'] || window['phonegap'] || window['PhoneGap']) &&
 /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(getUA()));
};
/**
 * Detect React Native.
 *
 * @return {boolean} True if ReactNative environment is detected.
 */
var isReactNative = function () {
 return (typeof navigator === 'object' && navigator['product'] === 'ReactNative');
};
/**
 * Detect Node.js.
 *
 * @return {boolean} True if Node.js environment is detected.
 */
var isNodeSdk = function () {
 return CONSTANTS.NODE_CLIENT === true || CONSTANTS.NODE_ADMIN === true;
};

var ERROR_NAME = 'FirebaseError';
var captureStackTrace = Error
 .captureStackTrace;
// Export for faking in tests
function patchCapture(captureFake) {
 var result = captureStackTrace;
 captureStackTrace = captureFake;
 return result;
}
var FirebaseError = /** @class */ (function () {
 function FirebaseError(code, message) {
 this.code = code;
 this.message = message;
 // We want the stack value, if implemented by Error
 if (captureStackTrace) {
 // Patches this.stack, omitted calls above ErrorFactory#create
 captureStackTrace(this, ErrorFactory.prototype.create);
 }
 else {
 try {
 // In case of IE11, stack will be set only after error is raised.
 // https://docs.microsoft.com/en-us/scripting/javascript/reference/stack-property-error-javascript
 throw Error.apply(this, arguments);
 }
 catch (err) {
 this.name = ERROR_NAME;
 // Make non-enumerable getter for the property.
 Object.defineProperty(this, 'stack', {
 get: function () {
 return err.stack;
 }
 });
 }
 }
 }
 return FirebaseError;
}());
// Back-door inheritance
FirebaseError.prototype = Object.create(Error.prototype);
FirebaseError.prototype.constructor = FirebaseError;
FirebaseError.prototype.name = ERROR_NAME;
var ErrorFactory = /** @class */ (function () {
 function ErrorFactory(service, serviceName, errors) {
 this.service = service;
 this.serviceName = serviceName;
 this.errors = errors;
 // Matches {$name}, by default.
 this.pattern = /\{\$([^}]+)}/g;
 // empty
 }
 ErrorFactory.prototype.create = function (code, data) {
 if (data === undefined) {
 data = {};
 }
 var template = this.errors[code];
 var fullCode = this.service + '/' + code;
 var message;
 if (template === undefined) {
 message = 'Error';
 }
 else {
 message = template.replace(this.pattern, function (match, key) {
 var value = data[key];
 return value !== undefined ? value.toString() : '<' + key + '?>';
 });
 }
 // Service: Error message (service/code).
 message = this.serviceName + ': ' + message + ' (' + fullCode + ').';
 var err = new FirebaseError(fullCode, message);
 // Populate the Error object with message parts for programmatic
 // accesses (e.g., e.file).
 for (var prop in data) {
 if (!data.hasOwnProperty(prop) || prop.slice(-1) === '_') {
 continue;
 }
 err[prop] = data[prop];
 }
 return err;
 };
 return ErrorFactory;
}());

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Evaluates a JSON string into a javascript object.
 *
 * @param {string} str A string containing JSON.
 * @return {*} The javascript object representing the specified JSON.
 */
function jsonEval(str) {
 return JSON.parse(str);
}
/**
 * Returns JSON representing a javascript object.
 * @param {*} data Javascript object to be stringified.
 * @return {string} The JSON contents of the object.
 */
function stringify(data) {
 return JSON.stringify(data);
}

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Decodes a Firebase auth. token into constituent parts.
 *
 * Notes:
 * - May return with invalid / incomplete claims if there's no native base64 decoding support.
 * - Doesn't check if the token is actually valid.
 *
 * @param {?string} token
 * @return {{header: *, claims: *, data: *, signature: string}}
 */
var decode = function (token) {
 var header = {}, claims = {}, data = {}, signature = '';
 try {
 var parts = token.split('.');
 header = jsonEval(base64Decode(parts[0]) || '');
 claims = jsonEval(base64Decode(parts[1]) || '');
 signature = parts[2];
 data = claims['d'] || {};
 delete claims['d'];
 }
 catch (e) { }
 return {
 header: header,
 claims: claims,
 data: data,
 signature: signature
 };
};
/**
 * Decodes a Firebase auth. token and checks the validity of its time-based claims. Will return true if the
 * token is within the time window authorized by the 'nbf' (not-before) and 'iat' (issued-at) claims.
 *
 * Notes:
 * - May return a false negative if there's no native base64 decoding support.
 * - Doesn't check if the token is actually valid.
 *
 * @param {?string} token
 * @return {boolean}
 */
var isValidTimestamp = function (token) {
 var claims = decode(token).claims, now = Math.floor(new Date().getTime() / 1000), validSince, validUntil;
 if (typeof claims === 'object') {
 if (claims.hasOwnProperty('nbf')) {
 validSince = claims['nbf'];
 }
 else if (claims.hasOwnProperty('iat')) {
 validSince = claims['iat'];
 }
 if (claims.hasOwnProperty('exp')) {
 validUntil = claims['exp'];
 }
 else {
 // token will expire after 24h by default
 validUntil = validSince + 86400;
 }
 }
 return (now && validSince && validUntil && now >= validSince && now <= validUntil);
};
/**
 * Decodes a Firebase auth. token and returns its issued at time if valid, null otherwise.
 *
 * Notes:
 * - May return null if there's no native base64 decoding support.
 * - Doesn't check if the token is actually valid.
 *
 * @param {?string} token
 * @return {?number}
 */
var issuedAtTime = function (token) {
 var claims = decode(token).claims;
 if (typeof claims === 'object' && claims.hasOwnProperty('iat')) {
 return claims['iat'];
 }
 return null;
};
/**
 * Decodes a Firebase auth. token and checks the validity of its format. Expects a valid issued-at time.
 *
 * Notes:
 * - May return a false negative if there's no native base64 decoding support.
 * - Doesn't check if the token is actually valid.
 *
 * @param {?string} token
 * @return {boolean}
 */
var isValidFormat = function (token) {
 var decoded = decode(token), claims = decoded.claims;
 return !!claims && typeof claims === 'object' && claims.hasOwnProperty('iat');
};
/**
 * Attempts to peer into an auth token and determine if it's an admin auth token by looking at the claims portion.
 *
 * Notes:
 * - May return a false negative if there's no native base64 decoding support.
 * - Doesn't check if the token is actually valid.
 *
 * @param {?string} token
 * @return {boolean}
 */
var isAdmin = function (token) {
 var claims = decode(token).claims;
 return typeof claims === 'object' && claims['admin'] === true;
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// See http://www.devthought.com/2012/01/18/an-object-is-not-a-hash/
var contains = function (obj, key) {
 return Object.prototype.hasOwnProperty.call(obj, key);
};
var safeGet = function (obj, key) {
 if (Object.prototype.hasOwnProperty.call(obj, key))
 return obj[key];
 // else return undefined.
};
/**
 * Enumerates the keys/values in an object, excluding keys defined on the prototype.
 *
 * @param {?Object.} obj Object to enumerate.
 * @param {!function(K, V)} fn Function to call for each key and value.
 * @template K,V
 */
var forEach = function (obj, fn) {
 for (var key in obj) {
 if (Object.prototype.hasOwnProperty.call(obj, key)) {
 fn(key, obj[key]);
 }
 }
};
/**
 * Copies all the (own) properties from one object to another.
 * @param {!Object} objTo
 * @param {!Object} objFrom
 * @return {!Object} objTo
 */
var extend = function (objTo, objFrom) {
 forEach(objFrom, function (key, value) {
 objTo[key] = value;
 });
 return objTo;
};
/**
 * Returns a clone of the specified object.
 * @param {!Object} obj
 * @return {!Object} cloned obj.
 */
var clone = function (obj) {
 return extend({}, obj);
};
/**
 * Returns true if obj has typeof "object" and is not null. Unlike goog.isObject(), does not return true
 * for functions.
 *
 * @param obj {*} A potential object.
 * @returns {boolean} True if it's an object.
 */
var isNonNullObject = function (obj) {
 return typeof obj === 'object' && obj !== null;
};
var isEmpty = function (obj) {
 for (var key in obj) {
 return false;
 }
 return true;
};
var getCount = function (obj) {
 var rv = 0;
 for (var key in obj) {
 rv++;
 }
 return rv;
};
var map = function (obj, f, opt_obj) {
 var res = {};
 for (var key in obj) {
 res[key] = f.call(opt_obj, obj[key], key, obj);
 }
 return res;
};
var findKey = function (obj, fn, opt_this) {
 for (var key in obj) {
 if (fn.call(opt_this, obj[key], key, obj)) {
 return key;
 }
 }
 return undefined;
};
var findValue = function (obj, fn, opt_this) {
 var key = findKey(obj, fn, opt_this);
 return key && obj[key];
};
var getAnyKey = function (obj) {
 for (var key in obj) {
 return key;
 }
};
var getValues = function (obj) {
 var res = [];
 var i = 0;
 for (var key in obj) {
 res[i++] = obj[key];
 }
 return res;
};
/**
 * Tests whether every key/value pair in an object pass the test implemented
 * by the provided function
 *
 * @param {?Object.} obj Object to test.
 * @param {!function(K, V)} fn Function to call for each key and value.
 * @template K,V
 */
var every = function (obj, fn) {
 for (var key in obj) {
 if (Object.prototype.hasOwnProperty.call(obj, key)) {
 if (!fn(key, obj[key])) {
 return false;
 }
 }
 }
 return true;
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Returns a querystring-formatted string (e.g. &arg=val&arg2=val2) from a params
 * object (e.g. {arg: 'val', arg2: 'val2'})
 * Note: You must prepend it with ? when adding it to a URL.
 *
 * @param {!Object} querystringParams
 * @return {string}
 */
var querystring = function (querystringParams) {
 var params = [];
 forEach(querystringParams, function (key, value) {
 if (Array.isArray(value)) {
 value.forEach(function (arrayVal) {
 params.push(encodeURIComponent(key) + '=' + encodeURIComponent(arrayVal));
 });
 }
 else {
 params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
 }
 });
 return params.length ? '&' + params.join('&') : '';
};
/**
 * Decodes a querystring (e.g. ?arg=val&arg2=val2) into a params object (e.g. {arg: 'val', arg2: 'val2'})
 *
 * @param {string} querystring
 * @return {!Object}
 */
var querystringDecode = function (querystring) {
 var obj = {};
 var tokens = querystring.replace(/^\?/, '').split('&');
 tokens.forEach(function (token) {
 if (token) {
 var key = token.split('=');
 obj[key[0]] = key[1];
 }
 });
 return obj;
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Copyright 2011 The Closure Library Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Abstract cryptographic hash interface.
 *
 * See Sha1 and Md5 for sample implementations.
 *
 */
/**
 * Create a cryptographic hash instance.
 *
 * @constructor
 * @struct
 */
var Hash = /** @class */ (function () {
 function Hash() {
 /**
 * The block size for the hasher.
 * @type {number}
 */
 this.blockSize = -1;
 }
 return Hash;
}());

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview SHA-1 cryptographic hash.
 * Variable names follow the notation in FIPS PUB 180-3:
 * http://csrc.nist.gov/publications/fips/fips180-3/fips180-3_final.pdf.
 *
 * Usage:
 * var sha1 = new sha1();
 * sha1.update(bytes);
 * var hash = sha1.digest();
 *
 * Performance:
 * Chrome 23: ~400 Mbit/s
 * Firefox 16: ~250 Mbit/s
 *
 */
/**
 * SHA-1 cryptographic hash constructor.
 *
 * The properties declared here are discussed in the above algorithm document.
 * @constructor
 * @extends {Hash}
 * @final
 * @struct
 */
var Sha1 = /** @class */ (function (_super) {
 __extends(Sha1, _super);
 function Sha1() {
 var _this = _super.call(this) || this;
 /**
 * Holds the previous values of accumulated variables a-e in the compress_
 * function.
 * @type {!Array}
 * @private
 */
 _this.chain_ = [];
 /**
 * A buffer holding the partially computed hash result.
 * @type {!Array}
 * @private
 */
 _this.buf_ = [];
 /**
 * An array of 80 bytes, each a part of the message to be hashed. Referred to
 * as the message schedule in the docs.
 * @type {!Array}
 * @private
 */
 _this.W_ = [];
 /**
 * Contains data needed to pad messages less than 64 bytes.
 * @type {!Array}
 * @private
 */
 _this.pad_ = [];
 /**
 * @private {number}
 */
 _this.inbuf_ = 0;
 /**
 * @private {number}
 */
 _this.total_ = 0;
 _this.blockSize = 512 / 8;
 _this.pad_[0] = 128;
 for (var i = 1; i < _this.blockSize; ++i) {
 _this.pad_[i] = 0;
 }
 _this.reset();
 return _this;
 }
 Sha1.prototype.reset = function () {
 this.chain_[0] = 0x67452301;
 this.chain_[1] = 0xefcdab89;
 this.chain_[2] = 0x98badcfe;
 this.chain_[3] = 0x10325476;
 this.chain_[4] = 0xc3d2e1f0;
 this.inbuf_ = 0;
 this.total_ = 0;
 };
 /**
 * Internal compress helper function.
 * @param {!Array|!Uint8Array|string} buf Block to compress.
 * @param {number=} opt_offset Offset of the block in the buffer.
 * @private
 */
 Sha1.prototype.compress_ = function (buf, opt_offset) {
 if (!opt_offset) {
 opt_offset = 0;
 }
 var W = this.W_;
 // get 16 big endian words
 if (typeof buf === 'string') {
 for (var i = 0; i < 16; i++) {
 // TODO(user): [bug 8140122] Recent versions of Safari for Mac OS and iOS
 // have a bug that turns the post-increment ++ operator into pre-increment
 // during JIT compilation. We have code that depends heavily on SHA-1 for
 // correctness and which is affected by this bug, so I've removed all uses
 // of post-increment ++ in which the result value is used. We can revert
 // this change once the Safari bug
 // (https://bugs.webkit.org/show_bug.cgi?id=109036) has been fixed and
 // most clients have been updated.
 W[i] =
 (buf.charCodeAt(opt_offset) << 24) |
 (buf.charCodeAt(opt_offset + 1) << 16) |
 (buf.charCodeAt(opt_offset + 2) << 8) |
 buf.charCodeAt(opt_offset + 3);
 opt_offset += 4;
 }
 }
 else {
 for (var i = 0; i < 16; i++) {
 W[i] =
 (buf[opt_offset] << 24) |
 (buf[opt_offset + 1] << 16) |
 (buf[opt_offset + 2] << 8) |
 buf[opt_offset + 3];
 opt_offset += 4;
 }
 }
 // expand to 80 words
 for (var i = 16; i < 80; i++) {
 var t = W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16];
 W[i] = ((t << 1) | (t >>> 31)) & 0xffffffff;
 }
 var a = this.chain_[0];
 var b = this.chain_[1];
 var c = this.chain_[2];
 var d = this.chain_[3];
 var e = this.chain_[4];
 var f, k;
 // TODO(user): Try to unroll this loop to speed up the computation.
 for (var i = 0; i < 80; i++) {
 if (i < 40) {
 if (i < 20) {
 f = d ^ (b & (c ^ d));
 k = 0x5a827999;
 }
 else {
 f = b ^ c ^ d;
 k = 0x6ed9eba1;
 }
 }
 else {
 if (i < 60) {
 f = (b & c) | (d & (b | c));
 k = 0x8f1bbcdc;
 }
 else {
 f = b ^ c ^ d;
 k = 0xca62c1d6;
 }
 }
 var t = (((a << 5) | (a >>> 27)) + f + e + k + W[i]) & 0xffffffff;
 e = d;
 d = c;
 c = ((b << 30) | (b >>> 2)) & 0xffffffff;
 b = a;
 a = t;
 }
 this.chain_[0] = (this.chain_[0] + a) & 0xffffffff;
 this.chain_[1] = (this.chain_[1] + b) & 0xffffffff;
 this.chain_[2] = (this.chain_[2] + c) & 0xffffffff;
 this.chain_[3] = (this.chain_[3] + d) & 0xffffffff;
 this.chain_[4] = (this.chain_[4] + e) & 0xffffffff;
 };
 Sha1.prototype.update = function (bytes, opt_length) {
 // TODO(johnlenz): tighten the function signature and remove this check
 if (bytes == null) {
 return;
 }
 if (opt_length === undefined) {
 opt_length = bytes.length;
 }
 var lengthMinusBlock = opt_length - this.blockSize;
 var n = 0;
 // Using local instead of member variables gives ~5% speedup on Firefox 16.
 var buf = this.buf_;
 var inbuf = this.inbuf_;
 // The outer while loop should execute at most twice.
 while (n < opt_length) {
 // When we have no data in the block to top up, we can directly process the
 // input buffer (assuming it contains sufficient data). This gives ~25%
 // speedup on Chrome 23 and ~15% speedup on Firefox 16, but requires that
 // the data is provided in large chunks (or in multiples of 64 bytes).
 if (inbuf == 0) {
 while (n <= lengthMinusBlock) {
 this.compress_(bytes, n);
 n += this.blockSize;
 }
 }
 if (typeof bytes === 'string') {
 while (n < opt_length) {
 buf[inbuf] = bytes.charCodeAt(n);
 ++inbuf;
 ++n;
 if (inbuf == this.blockSize) {
 this.compress_(buf);
 inbuf = 0;
 // Jump to the outer loop so we use the full-block optimization.
 break;
 }
 }
 }
 else {
 while (n < opt_length) {
 buf[inbuf] = bytes[n];
 ++inbuf;
 ++n;
 if (inbuf == this.blockSize) {
 this.compress_(buf);
 inbuf = 0;
 // Jump to the outer loop so we use the full-block optimization.
 break;
 }
 }
 }
 }
 this.inbuf_ = inbuf;
 this.total_ += opt_length;
 };
 /** @override */
 Sha1.prototype.digest = function () {
 var digest = [];
 var totalBits = this.total_ * 8;
 // Add pad 0x80 0x00*.
 if (this.inbuf_ < 56) {
 this.update(this.pad_, 56 - this.inbuf_);
 }
 else {
 this.update(this.pad_, this.blockSize - (this.inbuf_ - 56));
 }
 // Add # bits.
 for (var i = this.blockSize - 1; i >= 56; i--) {
 this.buf_[i] = totalBits & 255;
 totalBits /= 256; // Don't use bit-shifting here!
 }
 this.compress_(this.buf_);
 var n = 0;
 for (var i = 0; i < 5; i++) {
 for (var j = 24; j >= 0; j -= 8) {
 digest[n] = (this.chain_[i] >> j) & 255;
 ++n;
 }
 }
 return digest;
 };
 return Sha1;
}(Hash));

/**
 * Helper to make a Subscribe function (just like Promise helps make a
 * Thenable).
 *
 * @param executor Function which can make calls to a single Observer
 * as a proxy.
 * @param onNoObservers Callback when count of Observers goes to zero.
 */
function createSubscribe(executor, onNoObservers) {
 var proxy = new ObserverProxy(executor, onNoObservers);
 return proxy.subscribe.bind(proxy);
}
/**
 * Implement fan-out for any number of Observers attached via a subscribe
 * function.
 */
var ObserverProxy = /** @class */ (function () {
 /**
 * @param executor Function which can make calls to a single Observer
 * as a proxy.
 * @param onNoObservers Callback when count of Observers goes to zero.
 */
 function ObserverProxy(executor, onNoObservers) {
 var _this = this;
 this.observers = [];
 this.unsubscribes = [];
 this.observerCount = 0;
 // Micro-task scheduling by calling task.then().
 this.task = Promise.resolve();
 this.finalized = false;
 this.onNoObservers = onNoObservers;
 // Call the executor asynchronously so subscribers that are called
 // synchronously after the creation of the subscribe function
 // can still receive the very first value generated in the executor.
 this.task
 .then(function () {
 executor(_this);
 })
 .catch(function (e) {
 _this.error(e);
 });
 }
 ObserverProxy.prototype.next = function (value) {
 this.forEachObserver(function (observer) {
 observer.next(value);
 });
 };
 ObserverProxy.prototype.error = function (error) {
 this.forEachObserver(function (observer) {
 observer.error(error);
 });
 this.close(error);
 };
 ObserverProxy.prototype.complete = function () {
 this.forEachObserver(function (observer) {
 observer.complete();
 });
 this.close();
 };
 /**
 * Subscribe function that can be used to add an Observer to the fan-out list.
 *
 * - We require that no event is sent to a subscriber sychronously to their
 * call to subscribe().
 */
 ObserverProxy.prototype.subscribe = function (nextOrObserver, error, complete) {
 var _this = this;
 var observer;
 if (nextOrObserver === undefined &&
 error === undefined &&
 complete === undefined) {
 throw new Error('Missing Observer.');
 }
 // Assemble an Observer object when passed as callback functions.
 if (implementsAnyMethods(nextOrObserver, ['next', 'error', 'complete'])) {
 observer = nextOrObserver;
 }
 else {
 observer = {
 next: nextOrObserver,
 error: error,
 complete: complete
 };
 }
 if (observer.next === undefined) {
 observer.next = noop;
 }
 if (observer.error === undefined) {
 observer.error = noop;
 }
 if (observer.complete === undefined) {
 observer.complete = noop;
 }
 var unsub = this.unsubscribeOne.bind(this, this.observers.length);
 // Attempt to subscribe to a terminated Observable - we
 // just respond to the Observer with the final error or complete
 // event.
 if (this.finalized) {
 this.task.then(function () {
 try {
 if (_this.finalError) {
 observer.error(_this.finalError);
 }
 else {
 observer.complete();
 }
 }
 catch (e) {
 // nothing
 }
 return;
 });
 }
 this.observers.push(observer);
 return unsub;
 };
 // Unsubscribe is synchronous - we guarantee that no events are sent to
 // any unsubscribed Observer.
 ObserverProxy.prototype.unsubscribeOne = function (i) {
 if (this.observers === undefined || this.observers[i] === undefined) {
 return;
 }
 delete this.observers[i];
 this.observerCount -= 1;
 if (this.observerCount === 0 && this.onNoObservers !== undefined) {
 this.onNoObservers(this);
 }
 };
 ObserverProxy.prototype.forEachObserver = function (fn) {
 if (this.finalized) {
 // Already closed by previous event....just eat the additional values.
 return;
 }
 // Since sendOne calls asynchronously - there is no chance that
 // this.observers will become undefined.
 for (var i = 0; i < this.observers.length; i++) {
 this.sendOne(i, fn);
 }
 };
 // Call the Observer via one of it's callback function. We are careful to
 // confirm that the observe has not been unsubscribed since this asynchronous
 // function had been queued.
 ObserverProxy.prototype.sendOne = function (i, fn) {
 var _this = this;
 // Execute the callback asynchronously
 this.task.then(function () {
 if (_this.observers !== undefined && _this.observers[i] !== undefined) {
 try {
 fn(_this.observers[i]);
 }
 catch (e) {
 // Ignore exceptions raised in Observers or missing methods of an
 // Observer.
 // Log error to console. b/31404806
 if (typeof console !== 'undefined' && console.error) {
 console.error(e);
 }
 }
 }
 });
 };
 ObserverProxy.prototype.close = function (err) {
 var _this = this;
 if (this.finalized) {
 return;
 }
 this.finalized = true;
 if (err !== undefined) {
 this.finalError = err;
 }
 // Proxy is no longer needed - garbage collect references
 this.task.then(function () {
 _this.observers = undefined;
 _this.onNoObservers = undefined;
 });
 };
 return ObserverProxy;
}());
/** Turn synchronous function into one called asynchronously. */
function async(fn, onError) {
 return function () {
 var args = [];
 for (var _i = 0; _i < arguments.length; _i++) {
 args[_i] = arguments[_i];
 }
 Promise.resolve(true)
 .then(function () {
 fn.apply(void 0, args);
 })
 .catch(function (error) {
 if (onError) {
 onError(error);
 }
 });
 };
}
/**
 * Return true if the object passed in implements any of the named methods.
 */
function implementsAnyMethods(obj, methods) {
 if (typeof obj !== 'object' || obj === null) {
 return false;
 }
 for (var _i = 0, methods_1 = methods; _i < methods_1.length; _i++) {
 var method = methods_1[_i];
 if (method in obj && typeof obj[method] === 'function') {
 return true;
 }
 }
 return false;
}
function noop() {
 // do nothing
}

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Check to make sure the appropriate number of arguments are provided for a public function.
 * Throws an error if it fails.
 *
 * @param {!string} fnName The function name
 * @param {!number} minCount The minimum number of arguments to allow for the function call
 * @param {!number} maxCount The maximum number of argument to allow for the function call
 * @param {!number} argCount The actual number of arguments provided.
 */
var validateArgCount = function (fnName, minCount, maxCount, argCount) {
 var argError;
 if (argCount < minCount) {
 argError = 'at least ' + minCount;
 }
 else if (argCount > maxCount) {
 argError = maxCount === 0 ? 'none' : 'no more than ' + maxCount;
 }
 if (argError) {
 var error = fnName +
 ' failed: Was called with ' +
 argCount +
 (argCount === 1 ? ' argument.' : ' arguments.') +
 ' Expects ' +
 argError +
 '.';
 throw new Error(error);
 }
};
/**
 * Generates a string to prefix an error message about failed argument validation
 *
 * @param {!string} fnName The function name
 * @param {!number} argumentNumber The index of the argument
 * @param {boolean} optional Whether or not the argument is optional
 * @return {!string} The prefix to add to the error thrown for validation.
 */
function errorPrefix(fnName, argumentNumber, optional) {
 var argName = '';
 switch (argumentNumber) {
 case 1:
 argName = optional ? 'first' : 'First';
 break;
 case 2:
 argName = optional ? 'second' : 'Second';
 break;
 case 3:
 argName = optional ? 'third' : 'Third';
 break;
 case 4:
 argName = optional ? 'fourth' : 'Fourth';
 break;
 default:
 throw new Error('errorPrefix called with argumentNumber > 4. Need to update it?');
 }
 var error = fnName + ' failed: ';
 error += argName + ' argument ';
 return error;
}
/**
 * @param {!string} fnName
 * @param {!number} argumentNumber
 * @param {!string} namespace
 * @param {boolean} optional
 */
function validateNamespace(fnName, argumentNumber, namespace, optional) {
 if (optional && !namespace)
 return;
 if (typeof namespace !== 'string') {
 //TODO: I should do more validation here. We only allow certain chars in namespaces.
 throw new Error(errorPrefix(fnName, argumentNumber, optional) +
 'must be a valid firebase namespace.');
 }
}
function validateCallback(fnName, argumentNumber, callback, optional) {
 if (optional && !callback)
 return;
 if (typeof callback !== 'function')
 throw new Error(errorPrefix(fnName, argumentNumber, optional) +
 'must be a valid function.');
}
function validateContextObject(fnName, argumentNumber, context, optional) {
 if (optional && !context)
 return;
 if (typeof context !== 'object' || context === null)
 throw new Error(errorPrefix(fnName, argumentNumber, optional) +
 'must be a valid context object.');
}

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Code originally came from goog.crypt.stringToUtf8ByteArray, but for some reason they
// automatically replaced '\r\n' with '\n', and they didn't handle surrogate pairs,
// so it's been modified.
// Note that not all Unicode characters appear as single characters in JavaScript strings.
// fromCharCode returns the UTF-16 encoding of a character - so some Unicode characters
// use 2 characters in Javascript. All 4-byte UTF-8 characters begin with a first
// character in the range 0xD800 - 0xDBFF (the first character of a so-called surrogate
// pair).
// See http://www.ecma-international.org/ecma-262/5.1/#sec-15.1.3
/**
 * @param {string} str
 * @return {Array}
 */
var stringToByteArray$1 = function (str) {
 var out = [], p = 0;
 for (var i = 0; i < str.length; i++) {
 var c = str.charCodeAt(i);
 // Is this the lead surrogate in a surrogate pair?
 if (c >= 0xd800 && c <= 0xdbff) {
 var high = c - 0xd800; // the high 10 bits.
 i++;
 assert(i < str.length, 'Surrogate pair missing trail surrogate.');
 var low = str.charCodeAt(i) - 0xdc00; // the low 10 bits.
 c = 0x10000 + (high << 10) + low;
 }
 if (c < 128) {
 out[p++] = c;
 }
 else if (c < 2048) {
 out[p++] = (c >> 6) | 192;
 out[p++] = (c & 63) | 128;
 }
 else if (c < 65536) {
 out[p++] = (c >> 12) | 224;
 out[p++] = ((c >> 6) & 63) | 128;
 out[p++] = (c & 63) | 128;
 }
 else {
 out[p++] = (c >> 18) | 240;
 out[p++] = ((c >> 12) & 63) | 128;
 out[p++] = ((c >> 6) & 63) | 128;
 out[p++] = (c & 63) | 128;
 }
 }
 return out;
};
/**
 * Calculate length without actually converting; useful for doing cheaper validation.
 * @param {string} str
 * @return {number}
 */
var stringLength = function (str) {
 var p = 0;
 for (var i = 0; i < str.length; i++) {
 var c = str.charCodeAt(i);
 if (c < 128) {
 p++;
 }
 else if (c < 2048) {
 p += 2;
 }
 else if (c >= 0xd800 && c <= 0xdbff) {
 // Lead surrogate of a surrogate pair. The pair together will take 4 bytes to represent.
 p += 4;
 i++; // skip trail surrogate.
 }
 else {
 p += 3;
 }
 }
 return p;
};

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export { assert, assertionError, base64, base64Decode, base64Encode, CONSTANTS, deepCopy, deepExtend, patchProperty, Deferred, getUA, isMobileCordova, isNodeSdk, isReactNative, ErrorFactory, FirebaseError, patchCapture, jsonEval, stringify, decode, isAdmin, issuedAtTime, isValidFormat, isValidTimestamp, clone, contains, every, extend, findKey, findValue, forEach, getAnyKey, getCount, getValues, isEmpty, isNonNullObject, map, safeGet, querystring, querystringDecode, Sha1, async, createSubscribe, errorPrefix, validateArgCount, validateCallback, validateContextObject, validateNamespace, stringLength, stringToByteArray$1 as stringToByteArray };
