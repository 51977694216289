import 'whatwg-fetch';

// Store setTimeout reference so promise-polyfill will be unaffected by
// other code modifying setTimeout (like sinon.useFakeTimers())
var setTimeoutFunc = setTimeout;

function noop() {}

// Polyfill for Function.prototype.bind
function bind(fn, thisArg) {
 return function() {
 fn.apply(thisArg, arguments);
 };
}

function Promise(fn) {
 if (!(this instanceof Promise))
 throw new TypeError('Promises must be constructed via new');
 if (typeof fn !== 'function') throw new TypeError('not a function');
 this._state = 0;
 this._handled = false;
 this._value = undefined;
 this._deferreds = [];

 doResolve(fn, this);
}

function handle(self, deferred) {
 while (self._state === 3) {
 self = self._value;
 }
 if (self._state === 0) {
 self._deferreds.push(deferred);
 return;
 }
 self._handled = true;
 Promise._immediateFn(function() {
 var cb = self._state === 1 ? deferred.onFulfilled : deferred.onRejected;
 if (cb === null) {
 (self._state === 1 ? resolve : reject)(deferred.promise, self._value);
 return;
 }
 var ret;
 try {
 ret = cb(self._value);
 } catch (e) {
 reject(deferred.promise, e);
 return;
 }
 resolve(deferred.promise, ret);
 });
}

function resolve(self, newValue) {
 try {
 // Promise Resolution Procedure: https://github.com/promises-aplus/promises-spec#the-promise-resolution-procedure
 if (newValue === self)
 throw new TypeError('A promise cannot be resolved with itself.');
 if (
 newValue &&
 (typeof newValue === 'object' || typeof newValue === 'function')
 ) {
 var then = newValue.then;
 if (newValue instanceof Promise) {
 self._state = 3;
 self._value = newValue;
 finale(self);
 return;
 } else if (typeof then === 'function') {
 doResolve(bind(then, newValue), self);
 return;
 }
 }
 self._state = 1;
 self._value = newValue;
 finale(self);
 } catch (e) {
 reject(self, e);
 }
}

function reject(self, newValue) {
 self._state = 2;
 self._value = newValue;
 finale(self);
}

function finale(self) {
 if (self._state === 2 && self._deferreds.length === 0) {
 Promise._immediateFn(function() {
 if (!self._handled) {
 Promise._unhandledRejectionFn(self._value);
 }
 });
 }

 for (var i = 0, len = self._deferreds.length; i < len; i++) {
 handle(self, self._deferreds[i]);
 }
 self._deferreds = null;
}

function Handler(onFulfilled, onRejected, promise) {
 this.onFulfilled = typeof onFulfilled === 'function' ? onFulfilled : null;
 this.onRejected = typeof onRejected === 'function' ? onRejected : null;
 this.promise = promise;
}

/**
 * Take a potentially misbehaving resolver function and make sure
 * onFulfilled and onRejected are only called once.
 *
 * Makes no guarantees about asynchrony.
 */
function doResolve(fn, self) {
 var done = false;
 try {
 fn(
 function(value) {
 if (done) return;
 done = true;
 resolve(self, value);
 },
 function(reason) {
 if (done) return;
 done = true;
 reject(self, reason);
 }
 );
 } catch (ex) {
 if (done) return;
 done = true;
 reject(self, ex);
 }
}

Promise.prototype['catch'] = function(onRejected) {
 return this.then(null, onRejected);
};

Promise.prototype.then = function(onFulfilled, onRejected) {
 var prom = new this.constructor(noop);

 handle(this, new Handler(onFulfilled, onRejected, prom));
 return prom;
};

Promise.prototype['finally'] = function(callback) {
 var constructor = this.constructor;
 return this.then(
 function(value) {
 return constructor.resolve(callback()).then(function() {
 return value;
 });
 },
 function(reason) {
 return constructor.resolve(callback()).then(function() {
 return constructor.reject(reason);
 });
 }
 );
};

Promise.all = function(arr) {
 return new Promise(function(resolve, reject) {
 if (!arr || typeof arr.length === 'undefined')
 throw new TypeError('Promise.all accepts an array');
 var args = Array.prototype.slice.call(arr);
 if (args.length === 0) return resolve([]);
 var remaining = args.length;

 function res(i, val) {
 try {
 if (val && (typeof val === 'object' || typeof val === 'function')) {
 var then = val.then;
 if (typeof then === 'function') {
 then.call(
 val,
 function(val) {
 res(i, val);
 },
 reject
 );
 return;
 }
 }
 args[i] = val;
 if (--remaining === 0) {
 resolve(args);
 }
 } catch (ex) {
 reject(ex);
 }
 }

 for (var i = 0; i < args.length; i++) {
 res(i, args[i]);
 }
 });
};

Promise.resolve = function(value) {
 if (value && typeof value === 'object' && value.constructor === Promise) {
 return value;
 }

 return new Promise(function(resolve) {
 resolve(value);
 });
};

Promise.reject = function(value) {
 return new Promise(function(resolve, reject) {
 reject(value);
 });
};

Promise.race = function(values) {
 return new Promise(function(resolve, reject) {
 for (var i = 0, len = values.length; i < len; i++) {
 values[i].then(resolve, reject);
 }
 });
};

// Use polyfill for setImmediate for performance gains
Promise._immediateFn =
 (typeof setImmediate === 'function' &&
 function(fn) {
 setImmediate(fn);
 }) ||
 function(fn) {
 setTimeoutFunc(fn, 0);
 };

Promise._unhandledRejectionFn = function _unhandledRejectionFn(err) {
 if (typeof console !== 'undefined' && console) {
 console.warn('Possible Unhandled Promise Rejection:', err); // eslint-disable-line no-console
 }
};

var globalNS = (function() {
 // the only reliable means to get the global object is
 // `Function('return this')()`
 // However, this causes CSP violations in Chrome apps.
 if (typeof self !== 'undefined') {
 return self;
 }
 if (typeof window !== 'undefined') {
 return window;
 }
 if (typeof global !== 'undefined') {
 return global;
 }
 throw new Error('unable to locate global object');
})();

if (!globalNS.Promise) {
 globalNS.Promise = Promise;
}

function createCommonjsModule(fn, module) {
	return module = { exports: {} }, fn(module, module.exports), module.exports;
}

var _global = createCommonjsModule(function (module) {
// https://github.com/zloirock/core-js/issues/86#issuecomment-115759028
var global = module.exports = typeof window != 'undefined' && window.Math == Math
 ? window : typeof self != 'undefined' && self.Math == Math ? self
 // eslint-disable-next-line no-new-func
 : Function('return this')();
if (typeof __g == 'number') __g = global; // eslint-disable-line no-undef
});

var _core = createCommonjsModule(function (module) {
var core = module.exports = { version: '2.5.5' };
if (typeof __e == 'number') __e = core; // eslint-disable-line no-undef
});
var _core_1 = _core.version;

var _isObject = function (it) {
 return typeof it === 'object' ? it !== null : typeof it === 'function';
};

var _anObject = function (it) {
 if (!_isObject(it)) throw TypeError(it + ' is not an object!');
 return it;
};

var _fails = function (exec) {
 try {
 return !!exec();
 } catch (e) {
 return true;
 }
};

// Thank's IE8 for his funny defineProperty
var _descriptors = !_fails(function () {
 return Object.defineProperty({}, 'a', { get: function () { return 7; } }).a != 7;
});

var document = _global.document;
// typeof document.createElement is 'object' in old IE
var is = _isObject(document) && _isObject(document.createElement);
var _domCreate = function (it) {
 return is ? document.createElement(it) : {};
};

var _ie8DomDefine = !_descriptors && !_fails(function () {
 return Object.defineProperty(_domCreate('div'), 'a', { get: function () { return 7; } }).a != 7;
});

// 7.1.1 ToPrimitive(input [, PreferredType])

// instead of the ES6 spec version, we didn't implement @@toPrimitive case
// and the second argument - flag - preferred type is a string
var _toPrimitive = function (it, S) {
 if (!_isObject(it)) return it;
 var fn, val;
 if (S && typeof (fn = it.toString) == 'function' && !_isObject(val = fn.call(it))) return val;
 if (typeof (fn = it.valueOf) == 'function' && !_isObject(val = fn.call(it))) return val;
 if (!S && typeof (fn = it.toString) == 'function' && !_isObject(val = fn.call(it))) return val;
 throw TypeError("Can't convert object to primitive value");
};

var dP = Object.defineProperty;

var f = _descriptors ? Object.defineProperty : function defineProperty(O, P, Attributes) {
 _anObject(O);
 P = _toPrimitive(P, true);
 _anObject(Attributes);
 if (_ie8DomDefine) try {
 return dP(O, P, Attributes);
 } catch (e) { /* empty */ }
 if ('get' in Attributes || 'set' in Attributes) throw TypeError('Accessors not supported!');
 if ('value' in Attributes) O[P] = Attributes.value;
 return O;
};

var _objectDp = {
	f: f
};

var _propertyDesc = function (bitmap, value) {
 return {
 enumerable: !(bitmap & 1),
 configurable: !(bitmap & 2),
 writable: !(bitmap & 4),
 value: value
 };
};

var _hide = _descriptors ? function (object, key, value) {
 return _objectDp.f(object, key, _propertyDesc(1, value));
} : function (object, key, value) {
 object[key] = value;
 return object;
};

var hasOwnProperty = {}.hasOwnProperty;
var _has = function (it, key) {
 return hasOwnProperty.call(it, key);
};

var id = 0;
var px = Math.random();
var _uid = function (key) {
 return 'Symbol('.concat(key === undefined ? '' : key, ')_', (++id + px).toString(36));
};

var _redefine = createCommonjsModule(function (module) {
var SRC = _uid('src');
var TO_STRING = 'toString';
var $toString = Function[TO_STRING];
var TPL = ('' + $toString).split(TO_STRING);

_core.inspectSource = function (it) {
 return $toString.call(it);
};

(module.exports = function (O, key, val, safe) {
 var isFunction = typeof val == 'function';
 if (isFunction) _has(val, 'name') || _hide(val, 'name', key);
 if (O[key] === val) return;
 if (isFunction) _has(val, SRC) || _hide(val, SRC, O[key] ? '' + O[key] : TPL.join(String(key)));
 if (O === _global) {
 O[key] = val;
 } else if (!safe) {
 delete O[key];
 _hide(O, key, val);
 } else if (O[key]) {
 O[key] = val;
 } else {
 _hide(O, key, val);
 }
// add fake Function#toString for correct work wrapped methods / constructors with methods like LoDash isNative
})(Function.prototype, TO_STRING, function toString() {
 return typeof this == 'function' && this[SRC] || $toString.call(this);
});
});

var _aFunction = function (it) {
 if (typeof it != 'function') throw TypeError(it + ' is not a function!');
 return it;
};

// optional / simple context binding

var _ctx = function (fn, that, length) {
 _aFunction(fn);
 if (that === undefined) return fn;
 switch (length) {
 case 1: return function (a) {
 return fn.call(that, a);
 };
 case 2: return function (a, b) {
 return fn.call(that, a, b);
 };
 case 3: return function (a, b, c) {
 return fn.call(that, a, b, c);
 };
 }
 return function (/* ...args */) {
 return fn.apply(that, arguments);
 };
};

var PROTOTYPE = 'prototype';

var $export = function (type, name, source) {
 var IS_FORCED = type & $export.F;
 var IS_GLOBAL = type & $export.G;
 var IS_STATIC = type & $export.S;
 var IS_PROTO = type & $export.P;
 var IS_BIND = type & $export.B;
 var target = IS_GLOBAL ? _global : IS_STATIC ? _global[name] || (_global[name] = {}) : (_global[name] || {})[PROTOTYPE];
 var exports = IS_GLOBAL ? _core : _core[name] || (_core[name] = {});
 var expProto = exports[PROTOTYPE] || (exports[PROTOTYPE] = {});
 var key, own, out, exp;
 if (IS_GLOBAL) source = name;
 for (key in source) {
 // contains in native
 own = !IS_FORCED && target && target[key] !== undefined;
 // export native or passed
 out = (own ? target : source)[key];
 // bind timers to global for call from export context
 exp = IS_BIND && own ? _ctx(out, _global) : IS_PROTO && typeof out == 'function' ? _ctx(Function.call, out) : out;
 // extend global
 if (target) _redefine(target, key, out, type & $export.U);
 // export
 if (exports[key] != out) _hide(exports, key, exp);
 if (IS_PROTO && expProto[key] != out) expProto[key] = out;
 }
};
_global.core = _core;
// type bitmap
$export.F = 1; // forced
$export.G = 2; // global
$export.S = 4; // static
$export.P = 8; // proto
$export.B = 16; // bind
$export.W = 32; // wrap
$export.U = 64; // safe
$export.R = 128; // real proto method for `library`
var _export = $export;

var toString = {}.toString;

var _cof = function (it) {
 return toString.call(it).slice(8, -1);
};

// fallback for non-array-like ES3 and non-enumerable old V8 strings

// eslint-disable-next-line no-prototype-builtins
var _iobject = Object('z').propertyIsEnumerable(0) ? Object : function (it) {
 return _cof(it) == 'String' ? it.split('') : Object(it);
};

// 7.2.1 RequireObjectCoercible(argument)
var _defined = function (it) {
 if (it == undefined) throw TypeError("Can't call method on " + it);
 return it;
};

// 7.1.13 ToObject(argument)

var _toObject = function (it) {
 return Object(_defined(it));
};

// 7.1.4 ToInteger
var ceil = Math.ceil;
var floor = Math.floor;
var _toInteger = function (it) {
 return isNaN(it = +it) ? 0 : (it > 0 ? floor : ceil)(it);
};

// 7.1.15 ToLength

var min = Math.min;
var _toLength = function (it) {
 return it > 0 ? min(_toInteger(it), 0x1fffffffffffff) : 0; // pow(2, 53) - 1 == 9007199254740991
};

// 7.2.2 IsArray(argument)

var _isArray = Array.isArray || function isArray(arg) {
 return _cof(arg) == 'Array';
};

var SHARED = '__core-js_shared__';
var store = _global[SHARED] || (_global[SHARED] = {});
var _shared = function (key) {
 return store[key] || (store[key] = {});
};

var _wks = createCommonjsModule(function (module) {
var store = _shared('wks');

var Symbol = _global.Symbol;
var USE_SYMBOL = typeof Symbol == 'function';

var $exports = module.exports = function (name) {
 return store[name] || (store[name] =
 USE_SYMBOL && Symbol[name] || (USE_SYMBOL ? Symbol : _uid)('Symbol.' + name));
};

$exports.store = store;
});

var SPECIES = _wks('species');

var _arraySpeciesConstructor = function (original) {
 var C;
 if (_isArray(original)) {
 C = original.constructor;
 // cross-realm fallback
 if (typeof C == 'function' && (C === Array || _isArray(C.prototype))) C = undefined;
 if (_isObject(C)) {
 C = C[SPECIES];
 if (C === null) C = undefined;
 }
 } return C === undefined ? Array : C;
};

// 9.4.2.3 ArraySpeciesCreate(originalArray, length)


var _arraySpeciesCreate = function (original, length) {
 return new (_arraySpeciesConstructor(original))(length);
};

// 0 -> Array#forEach
// 1 -> Array#map
// 2 -> Array#filter
// 3 -> Array#some
// 4 -> Array#every
// 5 -> Array#find
// 6 -> Array#findIndex





var _arrayMethods = function (TYPE, $create) {
 var IS_MAP = TYPE == 1;
 var IS_FILTER = TYPE == 2;
 var IS_SOME = TYPE == 3;
 var IS_EVERY = TYPE == 4;
 var IS_FIND_INDEX = TYPE == 6;
 var NO_HOLES = TYPE == 5 || IS_FIND_INDEX;
 var create = $create || _arraySpeciesCreate;
 return function ($this, callbackfn, that) {
 var O = _toObject($this);
 var self = _iobject(O);
 var f = _ctx(callbackfn, that, 3);
 var length = _toLength(self.length);
 var index = 0;
 var result = IS_MAP ? create($this, length) : IS_FILTER ? create($this, 0) : undefined;
 var val, res;
 for (;length > index; index++) if (NO_HOLES || index in self) {
 val = self[index];
 res = f(val, index, O);
 if (TYPE) {
 if (IS_MAP) result[index] = res; // map
 else if (res) switch (TYPE) {
 case 3: return true; // some
 case 5: return val; // find
 case 6: return index; // findIndex
 case 2: result.push(val); // filter
 } else if (IS_EVERY) return false; // every
 }
 }
 return IS_FIND_INDEX ? -1 : IS_SOME || IS_EVERY ? IS_EVERY : result;
 };
};

// 22.1.3.31 Array.prototype[@@unscopables]
var UNSCOPABLES = _wks('unscopables');
var ArrayProto = Array.prototype;
if (ArrayProto[UNSCOPABLES] == undefined) _hide(ArrayProto, UNSCOPABLES, {});
var _addToUnscopables = function (key) {
 ArrayProto[UNSCOPABLES][key] = true;
};

// 22.1.3.8 Array.prototype.find(predicate, thisArg = undefined)

var $find = _arrayMethods(5);
var KEY = 'find';
var forced = true;
// Shouldn't skip holes
if (KEY in []) Array(1)[KEY](function () { forced = false; });
_export(_export.P + _export.F * forced, 'Array', {
 find: function find(callbackfn /* , that = undefined */) {
 return $find(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
 }
});
_addToUnscopables(KEY);

var find = _core.Array.find;

// 22.1.3.9 Array.prototype.findIndex(predicate, thisArg = undefined)

var $find$1 = _arrayMethods(6);
var KEY$1 = 'findIndex';
var forced$1 = true;
// Shouldn't skip holes
if (KEY$1 in []) Array(1)[KEY$1](function () { forced$1 = false; });
_export(_export.P + _export.F * forced$1, 'Array', {
 findIndex: function findIndex(callbackfn /* , that = undefined */) {
 return $find$1(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
 }
});
_addToUnscopables(KEY$1);

var findIndex = _core.Array.findIndex;

// to indexed object, toObject with fallback for non-array-like ES3 strings


var _toIobject = function (it) {
 return _iobject(_defined(it));
};

var max = Math.max;
var min$1 = Math.min;
var _toAbsoluteIndex = function (index, length) {
 index = _toInteger(index);
 return index < 0 ? max(index + length, 0) : min$1(index, length);
};

// false -> Array#indexOf
// true -> Array#includes



var _arrayIncludes = function (IS_INCLUDES) {
 return function ($this, el, fromIndex) {
 var O = _toIobject($this);
 var length = _toLength(O.length);
 var index = _toAbsoluteIndex(fromIndex, length);
 var value;
 // Array#includes uses SameValueZero equality algorithm
 // eslint-disable-next-line no-self-compare
 if (IS_INCLUDES && el != el) while (length > index) {
 value = O[index++];
 // eslint-disable-next-line no-self-compare
 if (value != value) return true;
 // Array#indexOf ignores holes, Array#includes - not
 } else for (;length > index; index++) if (IS_INCLUDES || index in O) {
 if (O[index] === el) return IS_INCLUDES || index || 0;
 } return !IS_INCLUDES && -1;
 };
};

var shared = _shared('keys');

var _sharedKey = function (key) {
 return shared[key] || (shared[key] = _uid(key));
};

var arrayIndexOf = _arrayIncludes(false);
var IE_PROTO = _sharedKey('IE_PROTO');

var _objectKeysInternal = function (object, names) {
 var O = _toIobject(object);
 var i = 0;
 var result = [];
 var key;
 for (key in O) if (key != IE_PROTO) _has(O, key) && result.push(key);
 // Don't enum bug & hidden keys
 while (names.length > i) if (_has(O, key = names[i++])) {
 ~arrayIndexOf(result, key) || result.push(key);
 }
 return result;
};

// IE 8- don't enum bug keys
var _enumBugKeys = (
 'constructor,hasOwnProperty,isPrototypeOf,propertyIsEnumerable,toLocaleString,toString,valueOf'
).split(',');

// 19.1.2.14 / 15.2.3.14 Object.keys(O)



var _objectKeys = Object.keys || function keys(O) {
 return _objectKeysInternal(O, _enumBugKeys);
};

var f$1 = Object.getOwnPropertySymbols;

var _objectGops = {
	f: f$1
};

var f$2 = {}.propertyIsEnumerable;

var _objectPie = {
	f: f$2
};

// 19.1.2.1 Object.assign(target, source, ...)





var $assign = Object.assign;

// should work with symbols and should have deterministic property order (V8 bug)
var _objectAssign = !$assign || _fails(function () {
 var A = {};
 var B = {};
 // eslint-disable-next-line no-undef
 var S = Symbol();
 var K = 'abcdefghijklmnopqrst';
 A[S] = 7;
 K.split('').forEach(function (k) { B[k] = k; });
 return $assign({}, A)[S] != 7 || Object.keys($assign({}, B)).join('') != K;
}) ? function assign(target, source) { // eslint-disable-line no-unused-vars
 var T = _toObject(target);
 var aLen = arguments.length;
 var index = 1;
 var getSymbols = _objectGops.f;
 var isEnum = _objectPie.f;
 while (aLen > index) {
 var S = _iobject(arguments[index++]);
 var keys = getSymbols ? _objectKeys(S).concat(getSymbols(S)) : _objectKeys(S);
 var length = keys.length;
 var j = 0;
 var key;
 while (length > j) if (isEnum.call(S, key = keys[j++])) T[key] = S[key];
 } return T;
} : $assign;

// 19.1.3.1 Object.assign(target, source)


_export(_export.S + _export.F, 'Object', { assign: _objectAssign });

var assign = _core.Object.assign;

// 7.2.8 IsRegExp(argument)


var MATCH = _wks('match');
var _isRegexp = function (it) {
 var isRegExp;
 return _isObject(it) && ((isRegExp = it[MATCH]) !== undefined ? !!isRegExp : _cof(it) == 'RegExp');
};

// helper for String#{startsWith, endsWith, includes}



var _stringContext = function (that, searchString, NAME) {
 if (_isRegexp(searchString)) throw TypeError('String#' + NAME + " doesn't accept regex!");
 return String(_defined(that));
};

var MATCH$1 = _wks('match');
var _failsIsRegexp = function (KEY) {
 var re = /./;
 try {
 '/./'[KEY](re);
 } catch (e) {
 try {
 re[MATCH$1] = false;
 return !'/./'[KEY](re);
 } catch (f) { /* empty */ }
 } return true;
};

var STARTS_WITH = 'startsWith';
var $startsWith = ''[STARTS_WITH];

_export(_export.P + _export.F * _failsIsRegexp(STARTS_WITH), 'String', {
 startsWith: function startsWith(searchString /* , position = 0 */) {
 var that = _stringContext(this, searchString, STARTS_WITH);
 var index = _toLength(Math.min(arguments.length > 1 ? arguments[1] : undefined, that.length));
 var search = String(searchString);
 return $startsWith
 ? $startsWith.call(that, search, index)
 : that.slice(index, index + search.length) === search;
 }
});

var startsWith = _core.String.startsWith;

var _stringRepeat = function repeat(count) {
 var str = String(_defined(this));
 var res = '';
 var n = _toInteger(count);
 if (n < 0 || n == Infinity) throw RangeError("Count can't be negative");
 for (;n > 0; (n >>>= 1) && (str += str)) if (n & 1) res += str;
 return res;
};

_export(_export.P, 'String', {
 // 21.1.3.13 String.prototype.repeat(count)
 repeat: _stringRepeat
});

var repeat = _core.String.repeat;

var _meta = createCommonjsModule(function (module) {
var META = _uid('meta');


var setDesc = _objectDp.f;
var id = 0;
var isExtensible = Object.isExtensible || function () {
 return true;
};
var FREEZE = !_fails(function () {
 return isExtensible(Object.preventExtensions({}));
});
var setMeta = function (it) {
 setDesc(it, META, { value: {
 i: 'O' + ++id, // object ID
 w: {} // weak collections IDs
 } });
};
var fastKey = function (it, create) {
 // return primitive with prefix
 if (!_isObject(it)) return typeof it == 'symbol' ? it : (typeof it == 'string' ? 'S' : 'P') + it;
 if (!_has(it, META)) {
 // can't set metadata to uncaught frozen object
 if (!isExtensible(it)) return 'F';
 // not necessary to add metadata
 if (!create) return 'E';
 // add missing metadata
 setMeta(it);
 // return object ID
 } return it[META].i;
};
var getWeak = function (it, create) {
 if (!_has(it, META)) {
 // can't set metadata to uncaught frozen object
 if (!isExtensible(it)) return true;
 // not necessary to add metadata
 if (!create) return false;
 // add missing metadata
 setMeta(it);
 // return hash weak collections IDs
 } return it[META].w;
};
// add metadata on freeze-family methods calling
var onFreeze = function (it) {
 if (FREEZE && meta.NEED && isExtensible(it) && !_has(it, META)) setMeta(it);
 return it;
};
var meta = module.exports = {
 KEY: META,
 NEED: false,
 fastKey: fastKey,
 getWeak: getWeak,
 onFreeze: onFreeze
};
});
var _meta_1 = _meta.KEY;
var _meta_2 = _meta.NEED;
var _meta_3 = _meta.fastKey;
var _meta_4 = _meta.getWeak;
var _meta_5 = _meta.onFreeze;

var def = _objectDp.f;

var TAG = _wks('toStringTag');

var _setToStringTag = function (it, tag, stat) {
 if (it && !_has(it = stat ? it : it.prototype, TAG)) def(it, TAG, { configurable: true, value: tag });
};

var f$3 = _wks;

var _wksExt = {
	f: f$3
};

var _library = false;

var defineProperty = _objectDp.f;
var _wksDefine = function (name) {
 var $Symbol = _core.Symbol || (_core.Symbol = _library ? {} : _global.Symbol || {});
 if (name.charAt(0) != '_' && !(name in $Symbol)) defineProperty($Symbol, name, { value: _wksExt.f(name) });
};

// all enumerable object keys, includes symbols



var _enumKeys = function (it) {
 var result = _objectKeys(it);
 var getSymbols = _objectGops.f;
 if (getSymbols) {
 var symbols = getSymbols(it);
 var isEnum = _objectPie.f;
 var i = 0;
 var key;
 while (symbols.length > i) if (isEnum.call(it, key = symbols[i++])) result.push(key);
 } return result;
};

var _objectDps = _descriptors ? Object.defineProperties : function defineProperties(O, Properties) {
 _anObject(O);
 var keys = _objectKeys(Properties);
 var length = keys.length;
 var i = 0;
 var P;
 while (length > i) _objectDp.f(O, P = keys[i++], Properties[P]);
 return O;
};

var document$1 = _global.document;
var _html = document$1 && document$1.documentElement;

// 19.1.2.2 / 15.2.3.5 Object.create(O [, Properties])



var IE_PROTO$1 = _sharedKey('IE_PROTO');
var Empty = function () { /* empty */ };
var PROTOTYPE$1 = 'prototype';

// Create object with fake `null` prototype: use iframe Object with cleared prototype
var createDict = function () {
 // Thrash, waste and sodomy: IE GC bug
 var iframe = _domCreate('iframe');
 var i = _enumBugKeys.length;
 var lt = '<';
 var gt = '>';
 var iframeDocument;
 iframe.style.display = 'none';
 _html.appendChild(iframe);
 iframe.src = 'javascript:'; // eslint-disable-line no-script-url
 // createDict = iframe.contentWindow.Object;
 // html.removeChild(iframe);
 iframeDocument = iframe.contentWindow.document;
 iframeDocument.open();
 iframeDocument.write(lt + 'script' + gt + 'document.F=Object' + lt + '/script' + gt);
 iframeDocument.close();
 createDict = iframeDocument.F;
 while (i--) delete createDict[PROTOTYPE$1][_enumBugKeys[i]];
 return createDict();
};

var _objectCreate = Object.create || function create(O, Properties) {
 var result;
 if (O !== null) {
 Empty[PROTOTYPE$1] = _anObject(O);
 result = new Empty();
 Empty[PROTOTYPE$1] = null;
 // add "__proto__" for Object.getPrototypeOf polyfill
 result[IE_PROTO$1] = O;
 } else result = createDict();
 return Properties === undefined ? result : _objectDps(result, Properties);
};

// 19.1.2.7 / 15.2.3.4 Object.getOwnPropertyNames(O)

var hiddenKeys = _enumBugKeys.concat('length', 'prototype');

var f$4 = Object.getOwnPropertyNames || function getOwnPropertyNames(O) {
 return _objectKeysInternal(O, hiddenKeys);
};

var _objectGopn = {
	f: f$4
};

// fallback for IE11 buggy Object.getOwnPropertyNames with iframe and window

var gOPN = _objectGopn.f;
var toString$1 = {}.toString;

var windowNames = typeof window == 'object' && window && Object.getOwnPropertyNames
 ? Object.getOwnPropertyNames(window) : [];

var getWindowNames = function (it) {
 try {
 return gOPN(it);
 } catch (e) {
 return windowNames.slice();
 }
};

var f$5 = function getOwnPropertyNames(it) {
 return windowNames && toString$1.call(it) == '[object Window]' ? getWindowNames(it) : gOPN(_toIobject(it));
};

var _objectGopnExt = {
	f: f$5
};

var gOPD = Object.getOwnPropertyDescriptor;

var f$6 = _descriptors ? gOPD : function getOwnPropertyDescriptor(O, P) {
 O = _toIobject(O);
 P = _toPrimitive(P, true);
 if (_ie8DomDefine) try {
 return gOPD(O, P);
 } catch (e) { /* empty */ }
 if (_has(O, P)) return _propertyDesc(!_objectPie.f.call(O, P), O[P]);
};

var _objectGopd = {
	f: f$6
};

// ECMAScript 6 symbols shim





var META = _meta.KEY;



















var gOPD$1 = _objectGopd.f;
var dP$1 = _objectDp.f;
var gOPN$1 = _objectGopnExt.f;
var $Symbol = _global.Symbol;
var $JSON = _global.JSON;
var _stringify = $JSON && $JSON.stringify;
var PROTOTYPE$2 = 'prototype';
var HIDDEN = _wks('_hidden');
var TO_PRIMITIVE = _wks('toPrimitive');
var isEnum = {}.propertyIsEnumerable;
var SymbolRegistry = _shared('symbol-registry');
var AllSymbols = _shared('symbols');
var OPSymbols = _shared('op-symbols');
var ObjectProto = Object[PROTOTYPE$2];
var USE_NATIVE = typeof $Symbol == 'function';
var QObject = _global.QObject;
// Don't use setters in Qt Script, https://github.com/zloirock/core-js/issues/173
var setter = !QObject || !QObject[PROTOTYPE$2] || !QObject[PROTOTYPE$2].findChild;

// fallback for old Android, https://code.google.com/p/v8/issues/detail?id=687
var setSymbolDesc = _descriptors && _fails(function () {
 return _objectCreate(dP$1({}, 'a', {
 get: function () { return dP$1(this, 'a', { value: 7 }).a; }
 })).a != 7;
}) ? function (it, key, D) {
 var protoDesc = gOPD$1(ObjectProto, key);
 if (protoDesc) delete ObjectProto[key];
 dP$1(it, key, D);
 if (protoDesc && it !== ObjectProto) dP$1(ObjectProto, key, protoDesc);
} : dP$1;

var wrap = function (tag) {
 var sym = AllSymbols[tag] = _objectCreate($Symbol[PROTOTYPE$2]);
 sym._k = tag;
 return sym;
};

var isSymbol = USE_NATIVE && typeof $Symbol.iterator == 'symbol' ? function (it) {
 return typeof it == 'symbol';
} : function (it) {
 return it instanceof $Symbol;
};

var $defineProperty = function defineProperty(it, key, D) {
 if (it === ObjectProto) $defineProperty(OPSymbols, key, D);
 _anObject(it);
 key = _toPrimitive(key, true);
 _anObject(D);
 if (_has(AllSymbols, key)) {
 if (!D.enumerable) {
 if (!_has(it, HIDDEN)) dP$1(it, HIDDEN, _propertyDesc(1, {}));
 it[HIDDEN][key] = true;
 } else {
 if (_has(it, HIDDEN) && it[HIDDEN][key]) it[HIDDEN][key] = false;
 D = _objectCreate(D, { enumerable: _propertyDesc(0, false) });
 } return setSymbolDesc(it, key, D);
 } return dP$1(it, key, D);
};
var $defineProperties = function defineProperties(it, P) {
 _anObject(it);
 var keys = _enumKeys(P = _toIobject(P));
 var i = 0;
 var l = keys.length;
 var key;
 while (l > i) $defineProperty(it, key = keys[i++], P[key]);
 return it;
};
var $create = function create(it, P) {
 return P === undefined ? _objectCreate(it) : $defineProperties(_objectCreate(it), P);
};
var $propertyIsEnumerable = function propertyIsEnumerable(key) {
 var E = isEnum.call(this, key = _toPrimitive(key, true));
 if (this === ObjectProto && _has(AllSymbols, key) && !_has(OPSymbols, key)) return false;
 return E || !_has(this, key) || !_has(AllSymbols, key) || _has(this, HIDDEN) && this[HIDDEN][key] ? E : true;
};
var $getOwnPropertyDescriptor = function getOwnPropertyDescriptor(it, key) {
 it = _toIobject(it);
 key = _toPrimitive(key, true);
 if (it === ObjectProto && _has(AllSymbols, key) && !_has(OPSymbols, key)) return;
 var D = gOPD$1(it, key);
 if (D && _has(AllSymbols, key) && !(_has(it, HIDDEN) && it[HIDDEN][key])) D.enumerable = true;
 return D;
};
var $getOwnPropertyNames = function getOwnPropertyNames(it) {
 var names = gOPN$1(_toIobject(it));
 var result = [];
 var i = 0;
 var key;
 while (names.length > i) {
 if (!_has(AllSymbols, key = names[i++]) && key != HIDDEN && key != META) result.push(key);
 } return result;
};
var $getOwnPropertySymbols = function getOwnPropertySymbols(it) {
 var IS_OP = it === ObjectProto;
 var names = gOPN$1(IS_OP ? OPSymbols : _toIobject(it));
 var result = [];
 var i = 0;
 var key;
 while (names.length > i) {
 if (_has(AllSymbols, key = names[i++]) && (IS_OP ? _has(ObjectProto, key) : true)) result.push(AllSymbols[key]);
 } return result;
};

// 19.4.1.1 Symbol([description])
if (!USE_NATIVE) {
 $Symbol = function Symbol() {
 if (this instanceof $Symbol) throw TypeError('Symbol is not a constructor!');
 var tag = _uid(arguments.length > 0 ? arguments[0] : undefined);
 var $set = function (value) {
 if (this === ObjectProto) $set.call(OPSymbols, value);
 if (_has(this, HIDDEN) && _has(this[HIDDEN], tag)) this[HIDDEN][tag] = false;
 setSymbolDesc(this, tag, _propertyDesc(1, value));
 };
 if (_descriptors && setter) setSymbolDesc(ObjectProto, tag, { configurable: true, set: $set });
 return wrap(tag);
 };
 _redefine($Symbol[PROTOTYPE$2], 'toString', function toString() {
 return this._k;
 });

 _objectGopd.f = $getOwnPropertyDescriptor;
 _objectDp.f = $defineProperty;
 _objectGopn.f = _objectGopnExt.f = $getOwnPropertyNames;
 _objectPie.f = $propertyIsEnumerable;
 _objectGops.f = $getOwnPropertySymbols;

 if (_descriptors && !_library) {
 _redefine(ObjectProto, 'propertyIsEnumerable', $propertyIsEnumerable, true);
 }

 _wksExt.f = function (name) {
 return wrap(_wks(name));
 };
}

_export(_export.G + _export.W + _export.F * !USE_NATIVE, { Symbol: $Symbol });

for (var es6Symbols = (
 // 19.4.2.2, 19.4.2.3, 19.4.2.4, 19.4.2.6, 19.4.2.8, 19.4.2.9, 19.4.2.10, 19.4.2.11, 19.4.2.12, 19.4.2.13, 19.4.2.14
 'hasInstance,isConcatSpreadable,iterator,match,replace,search,species,split,toPrimitive,toStringTag,unscopables'
).split(','), j = 0; es6Symbols.length > j;)_wks(es6Symbols[j++]);

for (var wellKnownSymbols = _objectKeys(_wks.store), k = 0; wellKnownSymbols.length > k;) _wksDefine(wellKnownSymbols[k++]);

_export(_export.S + _export.F * !USE_NATIVE, 'Symbol', {
 // 19.4.2.1 Symbol.for(key)
 'for': function (key) {
 return _has(SymbolRegistry, key += '')
 ? SymbolRegistry[key]
 : SymbolRegistry[key] = $Symbol(key);
 },
 // 19.4.2.5 Symbol.keyFor(sym)
 keyFor: function keyFor(sym) {
 if (!isSymbol(sym)) throw TypeError(sym + ' is not a symbol!');
 for (var key in SymbolRegistry) if (SymbolRegistry[key] === sym) return key;
 },
 useSetter: function () { setter = true; },
 useSimple: function () { setter = false; }
});

_export(_export.S + _export.F * !USE_NATIVE, 'Object', {
 // 19.1.2.2 Object.create(O [, Properties])
 create: $create,
 // 19.1.2.4 Object.defineProperty(O, P, Attributes)
 defineProperty: $defineProperty,
 // 19.1.2.3 Object.defineProperties(O, Properties)
 defineProperties: $defineProperties,
 // 19.1.2.6 Object.getOwnPropertyDescriptor(O, P)
 getOwnPropertyDescriptor: $getOwnPropertyDescriptor,
 // 19.1.2.7 Object.getOwnPropertyNames(O)
 getOwnPropertyNames: $getOwnPropertyNames,
 // 19.1.2.8 Object.getOwnPropertySymbols(O)
 getOwnPropertySymbols: $getOwnPropertySymbols
});

// 24.3.2 JSON.stringify(value [, replacer [, space]])
$JSON && _export(_export.S + _export.F * (!USE_NATIVE || _fails(function () {
 var S = $Symbol();
 // MS Edge converts symbol values to JSON as {}
 // WebKit converts symbol values to JSON as null
 // V8 throws on boxed symbols
 return _stringify([S]) != '[null]' || _stringify({ a: S }) != '{}' || _stringify(Object(S)) != '{}';
})), 'JSON', {
 stringify: function stringify(it) {
 var args = [it];
 var i = 1;
 var replacer, $replacer;
 while (arguments.length > i) args.push(arguments[i++]);
 $replacer = replacer = args[1];
 if (!_isObject(replacer) && it === undefined || isSymbol(it)) return; // IE8 returns string on undefined
 if (!_isArray(replacer)) replacer = function (key, value) {
 if (typeof $replacer == 'function') value = $replacer.call(this, key, value);
 if (!isSymbol(value)) return value;
 };
 args[1] = replacer;
 return _stringify.apply($JSON, args);
 }
});

// 19.4.3.4 Symbol.prototype[@@toPrimitive](hint)
$Symbol[PROTOTYPE$2][TO_PRIMITIVE] || _hide($Symbol[PROTOTYPE$2], TO_PRIMITIVE, $Symbol[PROTOTYPE$2].valueOf);
// 19.4.3.5 Symbol.prototype[@@toStringTag]
_setToStringTag($Symbol, 'Symbol');
// 20.2.1.9 Math[@@toStringTag]
_setToStringTag(Math, 'Math', true);
// 24.3.3 JSON[@@toStringTag]
_setToStringTag(_global.JSON, 'JSON', true);

// getting tag from 19.1.3.6 Object.prototype.toString()

var TAG$1 = _wks('toStringTag');
// ES3 wrong here
var ARG = _cof(function () { return arguments; }()) == 'Arguments';

// fallback for IE11 Script Access Denied error
var tryGet = function (it, key) {
 try {
 return it[key];
 } catch (e) { /* empty */ }
};

var _classof = function (it) {
 var O, T, B;
 return it === undefined ? 'Undefined' : it === null ? 'Null'
 // @@toStringTag case
 : typeof (T = tryGet(O = Object(it), TAG$1)) == 'string' ? T
 // builtinTag case
 : ARG ? _cof(O)
 // ES3 arguments fallback
 : (B = _cof(O)) == 'Object' && typeof O.callee == 'function' ? 'Arguments' : B;
};

// 19.1.3.6 Object.prototype.toString()

var test = {};
test[_wks('toStringTag')] = 'z';
if (test + '' != '[object z]') {
 _redefine(Object.prototype, 'toString', function toString() {
 return '[object ' + _classof(this) + ']';
 }, true);
}

_wksDefine('asyncIterator');

_wksDefine('observable');

var symbol = _core.Symbol;

// true -> String#at
// false -> String#codePointAt
var _stringAt = function (TO_STRING) {
 return function (that, pos) {
 var s = String(_defined(that));
 var i = _toInteger(pos);
 var l = s.length;
 var a, b;
 if (i < 0 || i >= l) return TO_STRING ? '' : undefined;
 a = s.charCodeAt(i);
 return a < 0xd800 || a > 0xdbff || i + 1 === l || (b = s.charCodeAt(i + 1)) < 0xdc00 || b > 0xdfff
 ? TO_STRING ? s.charAt(i) : a
 : TO_STRING ? s.slice(i, i + 2) : (a - 0xd800 << 10) + (b - 0xdc00) + 0x10000;
 };
};

var _iterators = {};

var IteratorPrototype = {};

// 25.1.2.1.1 %IteratorPrototype%[@@iterator]()
_hide(IteratorPrototype, _wks('iterator'), function () { return this; });

var _iterCreate = function (Constructor, NAME, next) {
 Constructor.prototype = _objectCreate(IteratorPrototype, { next: _propertyDesc(1, next) });
 _setToStringTag(Constructor, NAME + ' Iterator');
};

// 19.1.2.9 / 15.2.3.2 Object.getPrototypeOf(O)


var IE_PROTO$2 = _sharedKey('IE_PROTO');
var ObjectProto$1 = Object.prototype;

var _objectGpo = Object.getPrototypeOf || function (O) {
 O = _toObject(O);
 if (_has(O, IE_PROTO$2)) return O[IE_PROTO$2];
 if (typeof O.constructor == 'function' && O instanceof O.constructor) {
 return O.constructor.prototype;
 } return O instanceof Object ? ObjectProto$1 : null;
};

var ITERATOR = _wks('iterator');
var BUGGY = !([].keys && 'next' in [].keys()); // Safari has buggy iterators w/o `next`
var FF_ITERATOR = '@@iterator';
var KEYS = 'keys';
var VALUES = 'values';

var returnThis = function () { return this; };

var _iterDefine = function (Base, NAME, Constructor, next, DEFAULT, IS_SET, FORCED) {
 _iterCreate(Constructor, NAME, next);
 var getMethod = function (kind) {
 if (!BUGGY && kind in proto) return proto[kind];
 switch (kind) {
 case KEYS: return function keys() { return new Constructor(this, kind); };
 case VALUES: return function values() { return new Constructor(this, kind); };
 } return function entries() { return new Constructor(this, kind); };
 };
 var TAG = NAME + ' Iterator';
 var DEF_VALUES = DEFAULT == VALUES;
 var VALUES_BUG = false;
 var proto = Base.prototype;
 var $native = proto[ITERATOR] || proto[FF_ITERATOR] || DEFAULT && proto[DEFAULT];
 var $default = $native || getMethod(DEFAULT);
 var $entries = DEFAULT ? !DEF_VALUES ? $default : getMethod('entries') : undefined;
 var $anyNative = NAME == 'Array' ? proto.entries || $native : $native;
 var methods, key, IteratorPrototype;
 // Fix native
 if ($anyNative) {
 IteratorPrototype = _objectGpo($anyNative.call(new Base()));
 if (IteratorPrototype !== Object.prototype && IteratorPrototype.next) {
 // Set @@toStringTag to native iterators
 _setToStringTag(IteratorPrototype, TAG, true);
 // fix for some old engines
 if (!_library && typeof IteratorPrototype[ITERATOR] != 'function') _hide(IteratorPrototype, ITERATOR, returnThis);
 }
 }
 // fix Array#{values, @@iterator}.name in V8 / FF
 if (DEF_VALUES && $native && $native.name !== VALUES) {
 VALUES_BUG = true;
 $default = function values() { return $native.call(this); };
 }
 // Define iterator
 if ((!_library || FORCED) && (BUGGY || VALUES_BUG || !proto[ITERATOR])) {
 _hide(proto, ITERATOR, $default);
 }
 // Plug for library
 _iterators[NAME] = $default;
 _iterators[TAG] = returnThis;
 if (DEFAULT) {
 methods = {
 values: DEF_VALUES ? $default : getMethod(VALUES),
 keys: IS_SET ? $default : getMethod(KEYS),
 entries: $entries
 };
 if (FORCED) for (key in methods) {
 if (!(key in proto)) _redefine(proto, key, methods[key]);
 } else _export(_export.P + _export.F * (BUGGY || VALUES_BUG), NAME, methods);
 }
 return methods;
};

var $at = _stringAt(true);

// 21.1.3.27 String.prototype[@@iterator]()
_iterDefine(String, 'String', function (iterated) {
 this._t = String(iterated); // target
 this._i = 0; // next index
// 21.1.5.2.1 %StringIteratorPrototype%.next()
}, function () {
 var O = this._t;
 var index = this._i;
 var point;
 if (index >= O.length) return { value: undefined, done: true };
 point = $at(O, index);
 this._i += point.length;
 return { value: point, done: false };
});

var _iterStep = function (done, value) {
 return { value: value, done: !!done };
};

// 22.1.3.4 Array.prototype.entries()
// 22.1.3.13 Array.prototype.keys()
// 22.1.3.29 Array.prototype.values()
// 22.1.3.30 Array.prototype[@@iterator]()
var es6_array_iterator = _iterDefine(Array, 'Array', function (iterated, kind) {
 this._t = _toIobject(iterated); // target
 this._i = 0; // next index
 this._k = kind; // kind
// 22.1.5.2.1 %ArrayIteratorPrototype%.next()
}, function () {
 var O = this._t;
 var kind = this._k;
 var index = this._i++;
 if (!O || index >= O.length) {
 this._t = undefined;
 return _iterStep(1);
 }
 if (kind == 'keys') return _iterStep(0, index);
 if (kind == 'values') return _iterStep(0, O[index]);
 return _iterStep(0, [index, O[index]]);
}, 'values');

// argumentsList[@@iterator] is %ArrayProto_values% (9.4.4.6, 9.4.4.7)
_iterators.Arguments = _iterators.Array;

_addToUnscopables('keys');
_addToUnscopables('values');
_addToUnscopables('entries');

var ITERATOR$1 = _wks('iterator');
var TO_STRING_TAG = _wks('toStringTag');
var ArrayValues = _iterators.Array;

var DOMIterables = {
 CSSRuleList: true, // TODO: Not spec compliant, should be false.
 CSSStyleDeclaration: false,
 CSSValueList: false,
 ClientRectList: false,
 DOMRectList: false,
 DOMStringList: false,
 DOMTokenList: true,
 DataTransferItemList: false,
 FileList: false,
 HTMLAllCollection: false,
 HTMLCollection: false,
 HTMLFormElement: false,
 HTMLSelectElement: false,
 MediaList: true, // TODO: Not spec compliant, should be false.
 MimeTypeArray: false,
 NamedNodeMap: false,
 NodeList: true,
 PaintRequestList: false,
 Plugin: false,
 PluginArray: false,
 SVGLengthList: false,
 SVGNumberList: false,
 SVGPathSegList: false,
 SVGPointList: false,
 SVGStringList: false,
 SVGTransformList: false,
 SourceBufferList: false,
 StyleSheetList: true, // TODO: Not spec compliant, should be false.
 TextTrackCueList: false,
 TextTrackList: false,
 TouchList: false
};

for (var collections = _objectKeys(DOMIterables), i = 0; i < collections.length; i++) {
 var NAME = collections[i];
 var explicit = DOMIterables[NAME];
 var Collection = _global[NAME];
 var proto = Collection && Collection.prototype;
 var key;
 if (proto) {
 if (!proto[ITERATOR$1]) _hide(proto, ITERATOR$1, ArrayValues);
 if (!proto[TO_STRING_TAG]) _hide(proto, TO_STRING_TAG, NAME);
 _iterators[NAME] = ArrayValues;
 if (explicit) for (key in es6_array_iterator) if (!proto[key]) _redefine(proto, key, es6_array_iterator[key], true);
 }
}

var iterator = _wksExt.f('iterator');

/**
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
